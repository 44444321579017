<template>
  <div class="box">
    <div class="title">
      <div class="title-inside">
        <span :class="{ active: isShow }" @click="time1">今日</span
        ><span :class="{ active: isShowTwo }" @click="time2">昨日</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="time3"
          style="width: 280px; margin-left: 15px"
        >
        </el-date-picker>

        <el-button type="primary" @click="getAdreess" style="margin: 0 50px"
          >获取推广链接</el-button
        >
        <!-- <div>推广码：{{ code }}</div>
        <el-button type="success" style="margin-left: 15px" @click="getCode"
          >复制</el-button
        > -->
        <el-button
          type="primary"
          style="margin-left: 50px"
          @click="getPictureOne"
          >推广图片1</el-button
        >
        <el-button type="primary" style="margin-left: 15px"
          @click="getPictureTwo"
          >推广图片2</el-button
        >
      </div>
    </div>
    <main>
      <el-row :gutter="34">
        <!-- <el-col :span="4">
          <div class="item">
            <div class="top">推广注册用户</div>
            <div class="bottom">{{ data.registNum || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="item">
            <div class="top">推广登录用户</div>
            <div class="bottom">{{ data.loginNum || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="item">
            <div class="top">推广认证用户</div>
            <div class="bottom">{{ data.authNum || 0 }}</div>
          </div>
        </el-col> -->
        <el-col :span="4">
          <div class="item">
            <div class="top">总充值金额</div>
            <div class="bottom">{{ data.payMoney || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="item">
            <div class="top">总消耗金额</div>
            <div class="bottom">{{ data.pushMoney || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="item">
            <div class="top">总赠送金额</div>
            <div class="bottom">{{ data.refundMoney || 0 }}</div>
          </div>
        </el-col>
      </el-row>
    </main>
    <!-- <div
      class="pictureOne"
    >
    </div> -->
    <div
      :class="showPictureOne ? 'pictureOne' : 'pictureOne showHidden'"
      ref="foo"
      id="content"
    >
      <img :src="'data:image/png;base64,'+codeUrl" alt="">
    </div>
    <div
      :class="showPictureTwo ? 'pictureTwo' : 'pictureTwo showHidden'"
      id="code-img"
    >
      <img :src="'data:image/png;base64,'+codeUrl" alt="">
    </div>
    <a id="link"></a>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import {
  list_data,
  find_url,
  find_code,
  find_url_code,
} from "../../api/pushData";

function jialing(num) {
  if (num >= 10) {
    return num;
  } else {
    return "0" + num;
  }
}

var day1 = new Date();
day1.setTime(day1.getTime());
var s1 =
  day1.getFullYear() +
  "-" +
  jialing(day1.getMonth() + 1) +
  "-" +
  jialing(day1.getDate());
var day3 = new Date();
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
var s3 =
  day3.getFullYear() +
  "-" +
  jialing(day3.getMonth() + 1) +
  "-" +
  jialing(day3.getDate());
var day2 = new Date();
day2.setTime(day2.getTime() - 24 * 60 * 60 * 1000);
var s2 =
  day2.getFullYear() +
  "-" +
  jialing(day2.getMonth() + 1) +
  "-" +
  jialing(day2.getDate());
export default {
  data() {
    return {
      showPictureOne: false,
      showPictureTwo: false,
      headDateType: 2,
      value2: "",
      timeValue: [s1, s3],
      isShow: true,
      isShowTwo: false,
      codeUrl: "",
      code: "",
      fullscreenLoading: false,
      loading: "",
      data: {
        registNum: 0,
        loginNum: 0,
        authNum: 0,
        payNum: 0,
        payMoney: 0,
        pushMoney: 0,
        refundMoney: 0
      },
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 10, 1),
      status: {
        startTime: s1,
        endTime: s3,
      },
      address: "",
    };
  },
  created() {
    this.getDataList();
    find_url().then((res) => {
      if (res.code === 200) {
        this.address = res.data;
      }
    });
    find_code().then((res) => {
      if (res.code === 200) {
        this.code = res.data;
      }
    });
    find_url_code().then((res) => {
      if (res.code === 200) {
        this.codeUrl = res.data;
      }
    });
  },
  updated() {
    if (this.showPictureOne) {
      html2canvas(document.querySelector(".pictureOne"), {
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true, // 允许跨域图片
      }).then(async (canvas) => {
        let imgUrl = canvas.toDataURL();
        const data = await fetch(imgUrl);
        const blob = await data.blob();
        var link = document.getElementById("link");
        link.setAttribute("download", "推广图片1.png");
        link.setAttribute("href", imgUrl);
        link.click();
        this.showPictureOne = false;
        this.loading.close();
      });
      // html2canvas(document.querySelector(".pictureOne"), {
      //   useCORS: true, // 【重要】开启跨域配置
      //   allowTaint: true, // 允许跨域图片
      // }).then(async (canvas) => {
      //   let imgUrl = canvas.toDataURL();
      //   console.log(imgUrl);
      //   const data = await fetch(imgUrl);
      //   const blob = await data.blob();
      //   if (navigator.clipboard == undefined) {  
      //     //注:此方法只能在localhost跟https协议下可用,http协议下不存在此方法
      //     this.$message.error("请在https协议下操作");
      //     this.showPictureOne = false;
      //     this.loading.close();
      //     return false
      //   } else {
      //   await navigator.clipboard.write([
      //     // eslint-disable-next-line no-undef
      //     new ClipboardItem({
      //       [blob.type]: blob,
      //     }),
      //   ]);
      //     this.$message({
      //       message: "推广图片1",
      //       type: "success",
      //     });
      //     this.showPictureOne = false;
      //     this.loading.close();
      //   }
      // })
      // .catch(() => {
      //     this.$message({
      //       message: "请使用其他浏览器复制图片",
      //       type: "error",
      //     });
        this.showPictureOne = false;
        this.loading.close();
      // })
    }
    if (this.showPictureTwo) {
      html2canvas(document.querySelector(".pictureTwo"), {
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true, // 允许跨域图片
      }).then(async (canvas) => {
        let imgUrl = canvas.toDataURL();
        const data = await fetch(imgUrl);
        const blob = await data.blob();
        var link = document.getElementById("link");
        link.setAttribute("download", "推广图片2.png");
        link.setAttribute("href", imgUrl);
        link.click();
        this.showPictureTwo = false;
        this.loading.close();
      });
      // html2canvas(document.querySelector(".pictureTwo"), {
      //   useCORS: true, // 【重要】开启跨域配置
      //   allowTaint: true, // 允许跨域图片
      // }).then(async (canvas) => {
      //   let imgUrl = canvas.toDataURL();
      //   console.log(imgUrl);
      //   const data = await fetch(imgUrl);
      //   const blob = await data.blob();
      //   if (navigator.clipboard == undefined) {  
      //     //注:此方法只能在localhost跟https协议下可用,http协议下不存在此方法
      //     this.$message.error("请在https协议下操作");
      //     this.showPictureTwo = false;
      //     this.loading.close();
      //     return false
      //   } else {
      //   await navigator.clipboard.write([
      //     // eslint-disable-next-line no-undef
      //     new ClipboardItem({
      //       [blob.type]: blob,
      //     }),
      //   ]);
      //     this.$message({
      //       message: "推广图片2",
      //       type: "success",
      //     });
      //     this.showPictureTwo = false;
      //     this.loading.close();
      //   }
      // })
      // .catch(() => {
      //     this.$message({
      //       message: "请使用其他浏览器复制图片",
      //       type: "error",
      //     });
      // })
    }
  },
  methods: {
    getPictureOne() {
      this.showPictureOne = true;
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    getPictureTwo() {
      this.showPictureTwo = true;
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //获取推广码复制到粘贴板
    getCode() {
      const cInput = document.createElement("input");
      cInput.value = this.code;
      document.body.appendChild(cInput);
      cInput.select();
      document.execCommand("Copy");
      this.$message({
        message: this.code,
        type: "success",
      });
      cInput.remove();
    },
    //获取地址复制到粘贴板
    getAdreess() {
      const cInput = document.createElement("input");
      cInput.value = this.address;
      document.body.appendChild(cInput);
      cInput.select();
      document.execCommand("Copy");
      this.$message({
        message: this.address,
        type: "success",
      });
      cInput.remove();
    },
    time3(val) {
      this.status.startTime = val ? val[0] : "";
      this.status.endTime = val ? val[1] : "";
      this.isShowTwo = false;
      this.isShow = false;
      this.getDataList();
    },
    time2() {
      this.status.startTime = s2;
      this.status.endTime = s1;
      this.isShowTwo = true;
      this.isShow = false;
      this.timeValue = [];
      this.timeValue[0] = s2;
      this.timeValue[1] = s1;
      this.getDataList();
    },
    time1() {
      this.status.startTime = s1;
      this.status.endTime = s3;
      this.isShow = true;
      this.isShowTwo = false;
      this.timeValue = [];
      this.timeValue[0] = s1;
      this.timeValue[1] = s3;
      this.getDataList();
    },
    getDataList() {
      list_data(this.status).then((res) => {
        if (res.code === 200) {
          if (!res.data.records.length) {
            this.data.registNum = 0;
            this.data.loginNum = 0;
            this.data.authNum = 0;
            this.data.payNum = 0;
            this.data.refundMoney = 0;
            this.data.payMoney = 0;
            this.data.pushMoney = 0;
          } else {
            this.data = res.data.records[0];
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  padding: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.title p {
  color: rgba(40, 141, 248, 100);
  font-size: 18px;
  font-family: SourceHanSansSC-bold;
}

.title span {
  background-color: rgba(255, 255, 255, 100);
  text-align: center;
  border: 1px solid rgba(225, 225, 225, 100);
  color: #000;
  width: 60px;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
}

.title span:first-child {
  border-radius: 3px 0px 0px 3px;
}

.title span:nth-child(2) {
  border-radius: 0px 3px 3px 0px;
}

.title span.active {
  background-color: rgba(40, 141, 248, 100);
  color: #fff;
}

.item {
  background: #fbfbfb;
  padding: 10px;
  height: 111px;
  text-align: center;
}

.top {
  height: 45px;
  line-height: 45px;
  font-size: 18px;
}

.bottom {
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #bbbbbb;
  font-size: 18px;
  font-family: SourceHanSansSC-bold;
}

::v-deep .el-col-4 {
  margin-bottom: 20px;
}

::v-deep .el-input {
  background: #fbfbfb;
}

::v-deep .el-input input {
  background: #fbfbfb;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}
.title .title-inside {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pictureOne {
  margin-top: 100vh;
  width: 1242px;
  height: 2208px;
  // background: url("https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailezhanye/market/image/pictureOne.png")
  background: url(./pictureOne.png) no-repeat;
  background-size: 100%;
  // display: none !important;
}

.pictureOne img {
  width: 390px;
  height: 390px;
  margin-top: 1650px;
  margin-left: 725px;
}

.showHidden {
  display: none !important;
}

.pictureTwo {
  margin-top: 100vh;
  width: 1242px;
  height: 2208px;
  // background: url("https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailezhanye/market/image/pictureOne.png")
  background: url(./pictureTwo.png) no-repeat;
  background-size: 100%;
}

.pictureTwo img {
  width: 390px;
  height: 390px;
  margin-top: 1656px;
  margin-left: 425px;
}
</style>
