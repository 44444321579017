import request from './request.js';
//通过账户获取菜单
export function list_data(data) {
    return request({
        url: '/market-user/list-market-user-data',
        method: 'get',
        params: data
    })
}

//获取推广链接
export function find_url(data) {
    return request({
        url: '/market-user/find-url',
        method: 'get',
        params: data
    })
}

//获取推广码
export function find_code(data) {
    return request({
        url: '/market-user/find-code',
        method: 'get',
        params: data
    })
}

//获取二维码
export function find_url_code() {
    return request({
        url: '/market-user/find-qr-code',
        method: 'get'
    })
}